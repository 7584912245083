
export const storageUtils = {
  getItem,
  setItem
};

function getItem(key) {
  const item = window.window.localStorage.getItem(key);
  if (!item) {
    return null;
  }
  return JSON.parse(item);
}

function setItem(key, data) {
  window.localStorage.setItem(key, JSON.stringify(data));
}

