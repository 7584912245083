import { createSlice } from '@reduxjs/toolkit';
import { keys, some } from 'lodash-es';

import {
  allClipsDeleted,
  selectClipIdsByTab
} from '@features/clips';

import { idGenerator } from '@utils/id-generator';
import { modalUtils } from '@utils/modal';

import { EditTabModal } from '@components/EditTabModal';
import { CloseTabModal } from '@components/CloseTabModal';

const initialState = {
  currentTabId: '',
  items: {}
};

export function createNewTab() {
  return async function createNewTabThunk(dispatch) {
    const modalProps = {
      isEdit: false,
      label: ''
    };

    const { label, canceled } = await modalUtils.openModalAsync(EditTabModal, modalProps);

    if (!canceled && label) {
      const id = idGenerator.generateId();
      dispatch(tabAdded({ id, label }));
      dispatch(tabSelected({ id }));
    }
  }
}

export function editTab(tab) {
  return async function editTabThunk(dispatch) {
    if (!tab) {
      return;
    }

    const { id, label: currentLabel } = tab;

    const modalProps = {
      isEdit: true,
      label: currentLabel
    };

    const { label, canceled } = await modalUtils.openModalAsync(EditTabModal, modalProps);

    if (!canceled && label) {
      dispatch(tabUpdated({ id, label }));
    }
  }
}

export function closeTab(id) {
  return async function deleteTabThunk(dispatch, getState) {
    const state = getState();
    const clipIds = selectClipIdsByTab(id)(state);
    const hasClips = some(clipIds);

    const { mode, canceled } = hasClips ? await modalUtils.openModalAsync(CloseTabModal) : {};

    if (canceled) {
      return;
    }

    if (mode === 'hard' && hasClips) {
      dispatch(allClipsDeleted({ clipIds }));
    }

    dispatch(tabDeleted({ id }));
    dispatch(tabSelected({ id: '' }));
  }
}

export const tabsSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    tabAdded(state, action) {
      const { id, label } = action.payload;
      const tab = {
        id,
        label
      };
      state.items[id] = tab;
    },
    tabUpdated(state, action) {
      const { id, label } = action.payload;
      const tab = state.items[id];
      tab.label = label;
    },
    tabSelected(state, action) {
      const { id } = action.payload;
      state.currentTabId = id;
    },
    tabDeleted(state, action) {
      const { id } = action.payload;
      delete state.items[id];
    }
  }
});

export const {
  tabAdded,
  tabUpdated,
  tabSelected,
  tabDeleted
} = tabsSlice.actions;

export const selectTabIds = (state) => keys(state.tabs.items);

export const selectTab = (id) => (state) => state.tabs.items[id];

export const selectCurrentTabId = (state) => state.tabs.currentTabId;

export const tabsReducer = tabsSlice.reducer;

