import { configureStore } from '@reduxjs/toolkit';
import { debounce, reduce } from 'lodash-es';

import { storageUtils } from '@utils/storage';

import { clipsReducer } from '@features/clips';
import { tabsReducer } from '@features/tabs';

// debounce storage calls for performance
const saveStateDebounced = debounce(saveState, 300);

// load initial state
const preloadedState = loadState();

// set up the store
export const store = configureStore({
  reducer: {
    clips: clipsReducer,
    tabs: tabsReducer
  },
  preloadedState
});

// persist state on store updates
store.subscribe(() => {
  const state = store.getState();
  saveStateDebounced(state);
});

// helper functions for state persistence
function loadState() {
  const state = storageUtils.getItem('state');
  if (state) {
    return state;
  }

  // support backwards compatibility with the original setup
  const backwardsCompatibilityState = storageUtils.getItem('data');
  if (backwardsCompatibilityState) {
    // old state uses an array of clips, new state uses an object keyed by id
    // [{ id: '_asdf', text: 'asdf' }] --> { '_asdf': { id: '_asdf', text: 'asdf' } }
    const convertedItems = reduce(backwardsCompatibilityState, (result, value) => {
      result[value.id] = value;
      return result;
    }, {});

    return {
      clips: {
        items: convertedItems
      }
    };
  }

  return undefined;
}

function saveState(state) {
  storageUtils.setItem('state', state);
}

