import { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './EditTabModal.module.scss';

import { Modal } from '@components/Modal';

export function EditTabModal(props) {
  const { resolve, isEdit, label: currentLabel } = props;

  // state
  const [label, setLabel] = useState(currentLabel || '');

  const title = isEdit ? 'Edit tab' : 'New tab';

  function onSubmit(e) {
    e.preventDefault();
    const result = {
      label,
      canceled: false
    };
    resolve(result);
  }

  function cancel() {
    const result = {
      label: null,
      canceled: true
    };
    resolve(result);
  }

  return (
    <Modal
      title={title}
      onClose={cancel}
    >
      <form onSubmit={(e) => onSubmit(e)}>
        <input
          type="text"
          id="new-tab-name"
          placeholder="Tab name"
          autoFocus
          value={label}
          onChange={(e) => setLabel(e.target.value)}
        />

        <div className={styles.buttonsWrapper}>
          <button
            type="button"
            className="text-button"
            onClick={() => cancel()}
          >
            Cancel
          </button>

          <button
            type="submit"
            className="button"
          >
            Ok
          </button>
        </div>
      </form>
    </Modal>
  );
}

EditTabModal.propTypes = {
  resolve: PropTypes.func
};

