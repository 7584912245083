import { createSlice } from '@reduxjs/toolkit';
import { filter, map, keys } from 'lodash-es';

import { idGenerator } from '@utils/id-generator';

const initialState = {
  items: {}
};

export const clipsSlice = createSlice({
  name: 'clips',
  initialState,
  reducers: {
    clipAdded(state, action) {
      const { text, tabId } = action.payload;
      const id = idGenerator.generateId();
      const clip = {
        id,
        text,
        originalText: text,
        tabId
      };
      state.items[id] = clip;
    },
    clipUpdated(state, action) {
      const { id, text } = action.payload;
      const clip = state.items[id];
      clip.text = text;
    },
    clipReverted(state, action) {
      const { id } = action.payload;
      const clip = state.items[id];
      clip.text = clip.originalText;
    },
    clipDeleted(state, action) {
      const { id } = action.payload;
      delete state.items[id];
    },
    allClipsDeleted(state, action) {
      const { clipIds } = action.payload || {};
      if (!clipIds) {
        state.items = initialState.items;
        return;
      }
      for (const id of clipIds) {
        delete state.items[id];
      }
    }
  }
});

export const {
  clipAdded,
  clipUpdated,
  clipReverted,
  clipDeleted,
  allClipsDeleted
} = clipsSlice.actions;

export const selectClipIds = (state) => keys(state.clips.items);

export const selectClipIdsByTab = (tabId) => (state) => {
  const clips = filter(state.clips.items, { tabId });
  return map(clips, 'id');
};

export const selectClip = (id) => (state) => state.clips.items[id];

export const clipsReducer = clipsSlice.reducer;

