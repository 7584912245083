import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import styles from './App.module.scss';

import { MdAdd } from "react-icons/md";

import {
  selectClipIds,
  selectClipIdsByTab,
  allClipsDeleted
} from '@features/clips';

import {
  createNewTab,
  selectTabIds,
  selectCurrentTabId
} from '@features/tabs';

import { ClipForm } from '@components/ClipForm';
import { Clip } from '@components/Clip';
import { Emoji } from '@components/Emoji';
import { DeleteButton } from '@components/DeleteButton';
import { TabBar } from '@components/TabBar';
import { Tab } from '@components/Tab';

export function App() {

  // selectors
  const currentTabId = useSelector(selectCurrentTabId);
  const tabIds = useSelector(selectTabIds);

  const clipSelector = currentTabId ?
    selectClipIdsByTab(currentTabId) :
    selectClipIds;

  const clipIds = useSelector(clipSelector);

  // dispatch
  const dispatch = useDispatch();

  function addTab() {
    dispatch(createNewTab());
  }

  function removeAll() {
    const action = currentTabId ?
      allClipsDeleted({ clipIds }) :
      allClipsDeleted();
    dispatch(action);
  }

  return (
    <div>
      <div className={styles.row}>
        <TabBar>
          <Tab tabId="">All</Tab>
          {tabIds.map((id) => (
            <Tab tabId={id} key={id} />
          ))}
          <button
            type="button"
            className="icon-button"
            onClick={() => addTab()}
          >
            <MdAdd />
          </button>
        </TabBar>
      </div>

      <div className="container">

        <h1 className={styles.title}>
          <Emoji>&#x1f4cb;</Emoji>
        </h1>

        <div className={styles.row}>
          <ClipForm />
        </div>

        <div className={styles.row}>
          {clipIds.map((id) => (
            <Clip clipId={id} key={id} />
          ))}
        </div>

        <div className={styles.row}>
          {clipIds.length > 0 && (
            <DeleteButton
              onDelete={() => removeAll()}
              buttonClass="outline-button"
            >
              Remove all
            </DeleteButton>
          )}
        </div>
      </div>
    </div>
  );
}

