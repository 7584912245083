import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './Clip.module.scss';

import { reduxUtils } from '@utils/redux';

import {
  clipDeleted,
  clipReverted,
  clipUpdated,
  selectClip
} from '@features/clips';

import { MdClear, MdSync } from 'react-icons/md';

export function Clip(props) {
  const { clipId: id } = props;
  const inputElementId = `edit${id}`;

  // selectors
  const clip = useSelector(selectClip(id));

  // state
  const [text, setText] = useState(clip.text);

  // dispatch
  const dispatch = useDispatch();

  function copyText() {
    const element = document.getElementById(inputElementId);
    element.select();
    document.execCommand('copy');
  }

  function updateText(e) {
    setText(e.target.value);
    reduxUtils.dispatchDebounced(dispatch, clipUpdated({ id, text: e.target.value }));
  }

  function revert() {
    dispatch(clipReverted({ id }));
    setText(clip.originalText);
  }

  function remove() {
    dispatch(clipDeleted({ id }));
  }

  return (
    <div className={styles.item}>

      {/* copy button */}
      <button
        className={styles.copyButton}
        onClick={() => copyText()}
      >
        Copy
      </button>

      {/* text display/update */}
      <input
        type="text"
        id={inputElementId}
        className={styles.itemEdit}
        value={text}
        onChange={(e) => updateText(e)}
        aria-label="Edit"
      />

      {/* revert button */}
      {clip.text !== clip.originalText && (
        <button
          className={styles.clipActionButton}
          type="button"
          onClick={() => revert()}
          aria-label="Revert"
        >
          <MdSync />
        </button>
      )}

      {/* delete button */}
      <button
        className={styles.clipActionButton}
        type="button"
        onClick={() => remove()}
        aria-label="Delete"
      >
        <MdClear />
      </button>
    </div>
  );
}

Clip.propTypes = {
  clipId: PropTypes.string
};

