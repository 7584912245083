import PropTypes from 'prop-types';
import styles from './CloseTabModal.module.scss';

import { Modal } from '@components/Modal';
import { DeleteButton } from '@components/DeleteButton';

export function CloseTabModal(props) {
  const { resolve } = props;

  function onHardDelete(e) {
    const result = {
      mode: 'hard',
      canceled: false
    };
    resolve(result);
  }

  function onSoftDelete(e) {
    const result = {
      mode: 'soft',
      canceled: false
    };
    resolve(result);
  }

  function cancel() {
    const result = {
      mode: null,
      canceled: true
    };
    resolve(result);
  }

  return (
    <Modal
      title={'Close tab'}
      onClose={cancel}
    >
      <div className={styles.primaryButtons}>
        <p>
          This tab has some clips! What would you like to do?
        </p>

        <DeleteButton
          onDelete={() => onSoftDelete()}
          buttonClass="button"
        >
          Remove tab &amp; keep items
        </DeleteButton>

        <DeleteButton
          onDelete={() => onHardDelete()}
          buttonClass="button"
        >
          Remove tab &amp; delete items
        </DeleteButton>
      </div>

      <div className={styles.buttonsWrapper}>
        <button
          type="button"
          className="text-button"
          onClick={() => cancel()}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
}

CloseTabModal.propTypes = {
  resolve: PropTypes.func
};

