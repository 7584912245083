import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './Tab.module.scss';

import {
  editTab,
  closeTab,
  tabSelected,
  selectTab,
  selectCurrentTabId
} from '@features/tabs';

import { MdClear } from 'react-icons/md';

export function Tab(props) {
  const { tabId: id } = props;

  // selectors
  const tab = useSelector(selectTab(id));
  const currentTabId = useSelector(selectCurrentTabId);

  // dispatch
  const dispatch = useDispatch();

  const isSelected = currentTabId === id;

  let tabClass = isSelected ?
    `${styles.tab} ${styles.selected}` :
    styles.tab;

  if (!id) {
    tabClass += ` ${styles.noClose}`;
  }

  function handleClick(e) {
    dispatch(tabSelected({ id }));
    if (e.detail === 2) {
      // double-click to edit
      dispatch(editTab(tab));
    }
  }

  function handleClose(e) {
    e.stopPropagation();
    dispatch(closeTab(id))
  }

  return (
    <div
      className={tabClass}
      onClick={(e) => handleClick(e)}
    >
      <span>
        {props.children || tab.label}
      </span>

      {(!!id && isSelected) && (
        <span
          className={styles.closeIcon}
          onClick={(e) => handleClose(e)}
        >
          <MdClear />
        </span>
      )}
    </div>
  );
}

Tab.propTypes = {
  tabId: PropTypes.string
};

