import { debounce } from 'lodash-es';

export const reduxUtils = {
  dispatchDebounced: debounce(invokeDispatch, 500)
};

function invokeDispatch(dispatch, action) {
  dispatch(action);
}

