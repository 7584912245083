import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ClipForm.module.scss';

import { clipAdded } from '@features/clips';

import { selectCurrentTabId } from '@features/tabs';

export function ClipForm() {

  // state
  const [text, setText] = useState('');

  // selectors
  const tabId = useSelector(selectCurrentTabId);

  // dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    const element = document.getElementById('add-item-text');
    element.addEventListener('paste', onPaste);

    return () => {
      element.removeEventListener('paste', onPaste);
    };
  });

  function onPaste(e) {
    e.preventDefault();
    const text = e.clipboardData.getData('text');
    dispatch(clipAdded({ text, tabId }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(clipAdded({ text, tabId }));
    setText('');
  }

  return (
    <form
      id="add-item-form"
      className={styles.addItemForm}
      onSubmit={(e) => handleSubmit(e)}
    >
      <input
        type="text"
        id="add-item-text"
        placeholder="type or paste something here..."
        value={text}
        onChange={(e) => setText(e.target.value)}
      />

      <span className={styles.helpText}>
        (paste or press Enter to add)
      </span>
    </form>
  );
}

